import { gql } from '@apollo/client';
import { captureGraphQLException } from '../../../shared/logger';

export const GET_CART = gql`
  query Query($applicationId: String!) {
    applications {
      me {
        application(id: $applicationId) {
          getCart {
            applicationId
            total
            billTo {
              firstName
              lastName
              company
              phoneNumber
              address {
                line1
                city
                state
                postalCode
                country
              }
            }
            lineItems {
              name
              id
              displayName
              description
              price
              serviceType
              quantity
            }
          }
        }
      }
    }
  }
`;

export default client => {
  const getCart = async ({ variables }) => {
    return client
      .query({
        query: GET_CART,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          captureGraphQLException(errors);
          throw new Error('Error creating application cart.');
        }

        return data.applications.me.application.getCart;
      });
  };

  return {
    getCart,
  };
};
