/* eslint-disable import/prefer-default-export */
import posthog from 'posthog-js';
import * as Sentry from '@sentry/react';

export function captureException(error, tags) {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(error, tags);
  }

  Sentry.captureException(error);
  posthog.captureException(error, tags);
}

export function captureGraphQLException(errors) {
  errors.forEach(({ message, path }) => {
    captureException(message, {
      path,
    });
  });
}
