import { assign } from 'xstate';
import { useApolloClient } from '@apollo/client';
import posthog from 'posthog-js';

import { returnServiceByType } from '../../shared/functions';
import { captureException } from '../../shared/logger';
import invoiceMutations from '../../queries_mutations/Application/Invoice/mutations';

import queries from './queries';
import mutations from './mutations';

function createServices() {
  const client = useApolloClient();

  const { fetchServices, createCart } = queries(client);
  const { createInvoice } = mutations(client);
  const { markApplicationInvoiceAsProcessing } = invoiceMutations(client);

  return {
    guards: {},

    services: {
      fetchServices: ctx => fetchServices(ctx.applicationId),

      createCart: ctx =>
        createCart({
          applicationId: ctx.applicationId,
          serviceId: ctx.reviewService.id,
        }),

      createInvoice: ctx =>
        createInvoice({
          applicationId: ctx.applicationId,
          serviceId: ctx.reviewService.id,
        }),

      updateInvoiceProcessingStatus: (ctx, event) => {
        const { invoiceId } = event;

        return markApplicationInvoiceAsProcessing({
          variables: {
            applicationId: ctx.applicationId,
            invoiceId,
          },
        });
      },
    },

    actions: {
      fetchServicesSuccess: assign({
        reviewService: (_, e) => {
          const services = returnServiceByType(e.data, 'APPLICATION_REVIEW');

          if (services.length > 1) {
            posthog.captureException(
              new Error('Multiple Application Review Services Found'),
            );

            throw new Error('Multiple Application Review Services Found');
          }

          return services[0];
        },
      }),

      createCartSuccess: assign({
        cart: (_, e) => e.data,
      }),

      createInvoiceSuccess: assign({
        finalizedInvoice: (_, e) => e.data.finalized,
        invoiceId: (_, e) => e.data.id,
      }),

      logError: (_, e) => captureException(e),
    },
  };
}

export default createServices;
