import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import RoundedBlock from '../UI/RoundedBlock/RoundedBlock';
import BlockTable from '../UI/RoundedBlock/BlockTable/BlockTable';
import {
  MY_APPLICATIONS,
  GET_ALL_SERVICES,
} from '../../queries_mutations/queries';
import Key from '../UI/Key/Key';
import { ReactComponent as StickyIcon } from '../../assets/sticky-note.svg';
import { ReactComponent as PencilIcon } from '../../assets/pencil-square.svg';
import { ReactComponent as CreditCardIcon } from '../../assets/credit-card.svg';
import { ReactComponent as UpdateIcon } from '../../assets/update.svg';
import { returnService } from '../../shared/functions';

const ApplicationsList = () => {
  const { data, loading, refetch } = useQuery(MY_APPLICATIONS);
  const { data: servicesData, loading: servicesLoading } =
    useQuery(GET_ALL_SERVICES);
  const navigate = useNavigate();

  let sortedApplications = [];
  let applications = [];
  let services = [];

  let blockTitle = 'My Application';

  if (!loading) {
    applications = data?.applications?.me.all || []; // oject is frozen

    if (applications.length > 0) {
      blockTitle = 'My Applications';

      let tempSort = [...applications];
      tempSort = tempSort.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      );

      sortedApplications = tempSort;
    }
  }

  if (!servicesLoading) {
    services = servicesData.services.all;
  }

  const getService = id =>
    services.find(service => service.id === id).displayName;

  const editApplication = id =>
    navigate('/application', {
      state: { applicationId: id, isNewApplication: false },
    });

  const payApplication = id =>
    navigate('/application', {
      state: {
        applicationId: id,
        isNewApplication: false,
        skipToPayStep: true,
      },
    });

  const createApplicationButton = () =>
    navigate('/application', {
      state: { applicationId: undefined, isNewApplication: true },
    });

  const upgradeRush = id => navigate(`/dashboard/upgrade-rush/${id}`);
  const requestCopy = id => navigate(`/dashboard/copy-request/${id}`);
  const reviewApplication = id => navigate(`/dashboard/request-review/${id}`);
  const viewApplication = id => navigate(`/dashboard/app-view/${id}`);

  const tableHeaders = [
    ' ',
    'ORDER TYPE',
    'NAME',
    'CREATED',
    'REFERENCE #',
    'PROGRESS',
    'PAYMENT STATUS',
    'METHOD',
    'ACTIONS',
  ];

  return loading && servicesLoading ? (
    <p>Loading...</p>
  ) : (
    <div>
      <RoundedBlock
        title={blockTitle}
        btnHandler={() =>
          createApplicationButton(
            createApplicationButton({
              applicationId: undefined,
              isNewApplication: true,
            }),
          )
        }
        btnText="Create Application"
      >
        <div className="Box-content">
          <BlockTable id="applications" headers={tableHeaders}>
            {sortedApplications?.map(application => {
              const {
                id,
                createdAt,
                applicationType,
                paid,
                isProcessingPayment,
                refNumber,
                getStepComplete,
                deliveryInstructions,
                applicantInfo,
                evaluationCompleteDate,
                requestReview,
                status,
              } = application;

              let name = '--';

              const appCanceled =
                status === 'Canceled' || status === 'Cancelled';

              if (applicantInfo) {
                name = `${applicantInfo.lastName.toUpperCase()}, ${
                  applicantInfo.firstName
                }`;
              }

              /*
                THE FOLLOWING CHECKS IF THERE IS A REQUEST REVIEW DATE FROM DB. 
                
                IF THERE IS THEN THE APPLICATION HAS BEEN IN REVIEW BEFORE AND IT NEEDS TO GO OFF OF THAT DATE. 
                IF THERE IS NO DATE THEN IT NEEDS TO GO OFF OF THE COMPLETED DATE. 
                IF BOTH ARE NULL THEN IT IS FALSE
              */
              const daysFromNeededReview =
                process.env.NODE_ENV === 'development' ? 0 : 45; // todo: this should get put somewhere else. if they every want to change the number of days, we won't find it.

              let paidTimeFromTodayBool = false;
              if (requestReview) {
                paidTimeFromTodayBool =
                  dayjs().diff(dayjs(requestReview), 'day') >=
                  daysFromNeededReview;
              } else if (evaluationCompleteDate) {
                paidTimeFromTodayBool =
                  dayjs().diff(dayjs(evaluationCompleteDate), 'day') >=
                  daysFromNeededReview;
              }

              // CHECK IF THERE IS NO RUSH SERVICE
              let rushApplicationServiceID;
              if (deliveryInstructions) {
                rushApplicationServiceID =
                  deliveryInstructions.rushApplicationServiceID;
              }

              const rushService = returnService(
                services,
                rushApplicationServiceID,
              );

              let rowClass = '';

              let noRush = false;
              if (rushService) {
                if (
                  !rushService.displayName.toLowerCase().includes('no rush')
                ) {
                  rowClass = 'rush';
                }

                if (
                  rushService.displayName.toLowerCase().includes('no rush') &&
                  paid
                ) {
                  noRush = true;
                }

                if (!rushService.show) {
                  noRush = false;
                }
              }

              if (evaluationCompleteDate !== null) {
                noRush = false;
              }

              if (paidTimeFromTodayBool) {
                rowClass = 'reEvaluation';
              }

              if (status.toUpperCase() === 'COMPLETE') {
                rowClass = 'paid';
              }

              return (
                <tr className={rowClass} key={id}>
                  <td>
                    {paid && (
                      <button
                        className="__action viewButton"
                        type="button"
                        onClick={() => viewApplication(id)}
                      >
                        <strong>View</strong>
                      </button>
                    )}
                  </td>

                  {applicationType ? (
                    <td>
                      Evaluation -{' '}
                      {services.length > 0 && getService(applicationType?.id)}
                    </td>
                  ) : (
                    <td>--</td>
                  )}

                  <td>{name}</td>

                  <td>{dayjs(createdAt).format('MM/DD/YY')}</td>

                  <td>{refNumber}</td>

                  <td>
                    {paid
                      ? status
                      : getStepComplete === 0
                        ? '5/5'
                        : `${getStepComplete}/5`}
                  </td>

                  {isProcessingPayment ? (
                    <td>Processing Payment...</td>
                  ) : (
                    <td>{paid ? 'Paid' : 'Not complete'}</td>
                  )}

                  <td>
                    {rushApplicationServiceID
                      ? rushService?.displayName
                      : 'N/A'}
                  </td>

                  <td>
                    <div className="button-cont">
                      {!appCanceled && paid && (
                        <button
                          className="__action"
                          type="button"
                          onClick={() => requestCopy(id)}
                        >
                          <StickyIcon />
                          Request Copy
                        </button>
                      )}

                      {isProcessingPayment && (
                        <button
                          type="button"
                          className="__action"
                          onClick={() => refetch()}
                        >
                          <UpdateIcon />
                          Update Payment Status
                        </button>
                      )}

                      {!isProcessingPayment && (
                        <>
                          {!paid && (
                            <button
                              className="__action"
                              type="button"
                              onClick={() => editApplication(id)}
                            >
                              <PencilIcon />
                              Edit Application
                            </button>
                          )}

                          {!paid && getStepComplete === 0 && (
                            <button
                              type="button"
                              className="__action"
                              onClick={() => payApplication(id)}
                            >
                              <CreditCardIcon />
                              Pay Application Fee
                            </button>
                          )}
                        </>
                      )}

                      {paidTimeFromTodayBool && (
                        <button
                          type="button"
                          className="__action"
                          onClick={() => reviewApplication(id)}
                        >
                          <StickyIcon />
                          Request Review
                        </button>
                      )}
                      {!appCanceled && noRush && (
                        <button
                          type="button"
                          className="__action"
                          onClick={() => upgradeRush(id)}
                        >
                          <CreditCardIcon />
                          Add Rush Service
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </BlockTable>
        </div>
      </RoundedBlock>
      <Key rush complete review />
    </div>
  );
};

export default ApplicationsList;
