/* eslint-disable import/no-mutable-exports */
import * as yup from 'yup';

import { emailRegexPattern } from '../../../../shared/inputValidation';
import {
  PrevEvaluationSchema,
  NameSchema,
  AddressFormSchema,
  DateSchema,
} from '../shared/schemas';

const yupSchema = yup.object().shape({
  PrevEvaluation: yup.object().shape(PrevEvaluationSchema),
  gender: yup
    .string()
    .typeError('Please choose an option')
    .required('Please choose an option'),
  name: yup.object().shape(NameSchema),
  foreignName: yup.object().shape({
    option: yup.string().required('Please choose an option'),
    first: yup
      .string()
      .nullable()
      .when('option', {
        is: 'Different',
        then: schema => schema.required('Please enter your first name'),
      }),
    middle: yup.string().nullable(),
    last: yup
      .string()
      .nullable()
      .when('option', {
        is: 'Different',
        then: schema => schema.required('Please enter your last name'),
      }),
  }),
  address: yup.object().shape(AddressFormSchema),
  phoneNumber: yup.string().required('Please enter a phone number'),
  birthday: yup.object().shape(DateSchema),
  emailAddress: yup
    .string()
    .email('Please enter a valid email')
    .matches(emailRegexPattern, 'Please enter a valid email.')
    .required('Please enter an email'),
  birthCountry: yup.string().required('Please choose a country'),
});

export default yupSchema;
