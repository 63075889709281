import { gql } from '@apollo/client';
import { captureGraphQLException } from '../../../shared/logger';

export const GET_APPLICATION_AND_SERVICES = gql`
  query getApplication($appId: String!) {
    applications {
      me {
        application(id: $appId) {
          deliveryInstructions {
            id
            emailAddressToReturn
            mailOption
            mailAddress {
              id
              line1
              line2
              city
              state
              postalCode
              country
            }
          }
        }
      }
    }
    services {
      all {
        id
        name
        displayName
        description
        price
        serviceType
      }
    }
  }
`;

const CREATE_CART = gql`
  query CREATE_CART($applicationId: String!, $serviceId: String!) {
    applications {
      me {
        application(id: $applicationId) {
          cart {
            rush(serviceId: $serviceId) {
              applicationId
              total
              billTo {
                firstName
                lastName
                company
                phoneNumber
                address {
                  line1
                  city
                  state
                  postalCode
                  country
                }
              }
              lineItems {
                name
                id
                displayName
                description
                price
                serviceType
                quantity
              }
            }
          }
        }
      }
    }
  }
`;

export default client => {
  function getServices(id) {
    const variables = { appId: id };

    return client
      .query({ query: GET_APPLICATION_AND_SERVICES, variables })
      .then(({ data, errors }) => {
        if (errors) {
          captureGraphQLException(errors);
          throw new Error('Error while fetching application and services.');
        }

        return {
          services: data.services.all,
          address:
            data.applications.me.application.deliveryInstructions.mailAddress,
        };
      });
  }

  function createCart({ applicationId, serviceId }) {
    const variables = { applicationId, serviceId };
    return client
      .query({ query: CREATE_CART, variables })
      .then(({ data, errors }) => {
        if (errors) {
          captureGraphQLException(errors);
          throw new Error('Error while creating upgrade rush cart.');
        }

        return data.applications.me.application.cart.rush;
      });
  }

  return {
    getServices,
    createCart,
  };
};
