import { createMachine } from 'xstate';
import machineActions from './machineActions';

function createConfig(id = '') {
  const actions = machineActions();

  return createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOgNwBcBiCAe0LPwDdaBrMEtLPQ08ihARaZ0FXPQDaABgC60mYlAAHWrErj8ikAA9EAWgCMAThIBmAOxSpAJiMAOIwFZHAFmsvTAGhABPfUZMANkcjc2CpIwNHAxcXIwBfeO8uHAJiRkoqMAAnbNpskiUAG1EAM3zUTgxU3gyBIVoRMUlZeS0VNWbNJB1EaztTM0DTQMtTAccLL199YwMSaxG7Gxc7Rzs3DcTk6p50gHd0dXwoKgBlAFUAIQBZAEkAFTaejvV6LV0EAwGSe1HnKRRQLfYbePwIfokAwGKSBKQWayORbubYgFJ7UiYbJgURgADC6Gy1DoDAa7Cq3DSmOxuIJRMEzEaog08meylUb26oE+4ykUKiLikLmBwycYMQBlMfOM5nMpliLmcgTsqPRVJIWJxFHxhOoOTyBWKZQqFJq6U1tN1DOEzJaclk7Q5XQ+iHWJllbsCwxCIxc4ohQSF5jcUQGRnGLnMqt26ot2rujNwmDANHoHDJHDVtTjYATLCTYGtTK6rIdLydGhdCARC2GG2s7mhRkW-sFJg8BlsrmDRkBpmjlOzNPjieTWVy+UKJQo5WylSz5uHudHhYaTRZrTL7M6lZ6nzh8yMowMwJCsSi1n9-TsJDPLm+MP61hiA7NpHQh2OUAACugfKgwHwag8QAeRub8ABkAFEHiggARNkQFeZ090QUxQjMYNonGdDJWbP0ZgQYZAhIBxw0CWIjycQIEiSNEY1qD8jjEE5f3-QDgIAQQAOTxKCIOghCtyQit3lQhA9ClRwSEBAxzCMSNEWWDYCPBVwb0cKQ7GfdYhWI18MUKP8AKAgl8GTIoikgVNSUZckF1IJRjI4syLKsiAi3XO1EOQ3duX0Z8XFvLtzGfUxjGGBsryCGJNICbD+kVFU6Icoz2NM9BzLASzrP1ScjRnE1Uqc9KKFc7L3M8218FLBRyx3MT-IkxVzFI6wwnhRU23Cxx-VlIKj1C0x2rscxomBRI6PwWgIDgLQHMdBquV6CTogG1ZzGMBS5SsQJ-Uk+FSKkTaYRiVYnxcAz1X4RbOSrSTBgbe9w3kuxRtDfbnxI1Znq9YVwvQq7ak-FioFulCmvQ0jlURIVOwcKQkSvVrZWsWFrBo0IQsulKGMXLUdSJcG-JWiwguOqYuwUpwolbMxHDlQJFj+UxTHWftccHfHcTzWgC2JxrSdlGTVnWGFew2ZtzCvYYzEjGx0LcNn4SB9ImK-NiTIoAXlp5H54TWMJHFGKx+n9dSoRo0ZI3kyVhtVxznIyrKcogHX7uFEiFPegJIylRG9sI9xfhlf64gbSIPAdozcDd+q7vEjxpQFKxdqo6ZwSGhZ5a9cK7Aomxo6KWh0AgAgoCgidsnd8S3ShMIIvDWEkv24xBmO-DQjJ+85Wjkqtcrg0a6at6SKRCwojlC8RivfOSDGmUtOiBtEUm+IgA */
      initial: 'init',
      context: {
        applicationId: id,
        finalizedInvoice: null,
        cart: null,
      },

      states: {
        init: {
          invoke: {
            src: 'getAppAndServices',
            onDone: {
              actions: ['addAddressAndServices'],
              target: 'waiting',
            },
            onError: 'loadingError',
          },
        },

        waiting: {
          on: {
            SUBMIT: {
              actions: 'onSubmit',
              target: 'createCart',
            },
          },
        },

        createCart: {
          invoke: {
            src: 'createCart',
            onDone: {
              actions: 'createCartSuccess',
              target: 'createInvoice',
            },
            onError: 'paymentError',
          },
        },

        createInvoice: {
          invoke: {
            src: 'createInvoice',
            onDone: {
              actions: 'createInvoiceSuccess',
              target: 'awaitingPayment',
            },
            onError: 'paymentError',
          },
        },

        awaitingPayment: {
          on: {
            COMPLETED: 'processingPayment',
            CANCELLED: 'waiting',
          },
        },

        processingPayment: {
          invoke: {
            src: 'updateInvoiceProcessingStatus',
            onDone: 'paid',
            onError: 'paymentError',
          },
        },

        paid: {
          type: 'final',
        },

        loadingError: {
          type: 'final',
          entry: 'logError',
        },

        paymentError: {
          type: 'final',
          entry: 'logError',
        },
      },
    },
    actions,
  );
}

export default createConfig;
