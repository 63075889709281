import { gql } from '@apollo/client';
import { captureGraphQLException } from '../../../shared/logger';

export const CREATE_COPY_REQUEST = gql`
  mutation createCopyRequest($data: CopyRequestInput!) {
    copyRequest {
      add(data: $data) {
        id
      }
    }
  }
`;

export const CREATE_ADDRESSES = gql`
  mutation createCopyRequestAddresses($data: [addressInput]) {
    copyRequest {
      addAddresses(addresses: $data) {
        id
      }
    }
  }
`;

const MARK_COPY_REQUEST_INVOICE_AS_PROCESSING = gql`
  mutation markApplicationInvoiceAsProcessing(
    $applicationId: String!
    $copyRequestId: String!
    $invoiceId: String!
  ) {
    copyRequest {
      invoices(applicationId: $applicationId, copyRequestId: $copyRequestId) {
        invoice(id: $invoiceId) {
          markAsProcessing
        }
      }
    }
  }
`;

const VOID_INVOICE = gql`
  mutation VOID_INVOICE($applicationId: String!, $copyRequestId: String!) {
    copyRequest {
      invoices(applicationId: $applicationId, copyRequestId: $copyRequestId) {
        invoice {
          void
        }
      }
    }
  }
`;

export default client => {
  function createCopyRequest(copyRequestData) {
    const { addresses, emailPDFS, applicationId, returnDocs } = copyRequestData;
    const emailArray = JSON.stringify(emailPDFS.map(email => email));
    const returnDocsString = JSON.stringify(returnDocs);

    const variables = {
      data: {
        applicationId,
        emails: emailArray,
        returnDocs: returnDocsString,
      },
    };

    return client
      .mutate({
        mutation: CREATE_COPY_REQUEST,
        variables,
      })
      .then(async ({ data, errors }) => {
        if (errors) {
          captureGraphQLException(errors);
          throw new Error('Error while creating copy request.');
        }

        const copyRequestId = data.copyRequest.add.id;

        if (addresses.length > 0) {
          const addressesWithCopyRequestID = addresses.map(address => {
            const addressWorking = address;
            delete addressWorking.type;
            return {
              ...addressWorking,
              copyRequestId,
            };
          });

          // todo.. what are we doing here...we're not handing gql errors
          const address = await client.mutate({
            mutation: CREATE_ADDRESSES,
            variables: { data: addressesWithCopyRequestID },
          });

          return { address, copyRequestId };
        }

        return { copyRequestId };
      });
  }

  function voidInvoice({ applicationId, copyRequestId }) {
    return client
      .mutate({
        mutation: VOID_INVOICE,
        variables: {
          applicationId,
          copyRequestId,
        },
      })
      .then(({ data, errors }) => {
        if (errors) {
          captureGraphQLException(errors);
          throw new Error('Error while trying to void invoice.');
        }

        return data.copyRequest.invoices.invoice.void;
      });
  }

  function markCopyRequestInvoiceAsProcessing({ variables }) {
    return client
      .mutate({
        mutation: MARK_COPY_REQUEST_INVOICE_AS_PROCESSING,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          captureGraphQLException(errors);
          throw new Error('Error marking invoice as processing.');
        }

        const { markAsProcessing } = data.copyRequest.invoices.invoice;

        return markAsProcessing;
      });
  }

  return {
    createCopyRequest,
    voidInvoice,
    markCopyRequestInvoiceAsProcessing,
  };
};
