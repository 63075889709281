import React from 'react';
import { useMachine } from '@xstate/react';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { debug } from '../../../../envConfig';
import machine from './MachineSettings';
import {
  constructAddress,
  clipboardCopy,
  returnService,
  camelCase,
} from '../../../../shared/functions';

import { ReactComponent as StickyNote } from '../../../../assets/sticky-note.svg';
import { CopyRequestStyled, EmailStyled, AddressStyled } from './styles';
import RoundedBlock from '../../../UI/RoundedBlock/RoundedBlock';
import {
  Button,
  IconButton,
  ClipboardButton,
  InternalNotesStyled,
  ButtonsCont,
  ConfirmationStyled,
} from '../../../../shared/styles';

const AdminCopyRequestReview = () => {
  const { id: copyRequestID } = useParams();
  const navigate = useNavigate();
  const [formState, send] = useMachine(machine(copyRequestID), {
    devTools: debug.xstate,
  });

  if (formState.matches('init')) {
    return <p>Loading...</p>;
  }

  // once loaded
  if (!formState.matches('init') || !formState.matches('loadingFailed')) {
    // const {id, createdAt, application, status, completed, addresses, emails} = formState.context;
    const {
      createdAt,
      application,
      copiesRequested,
      addresses,
      services,
      emails,
      copyReqeustId,
      returnDocs,
    } = formState.context;

    let returnDocsShipping;

    if (returnDocs) {
      returnDocsShipping = returnService(services, returnDocs.shipping_method);
    }

    const saveNotes = () => {
      const notes = document.getElementById('internalNotes').value;
      const payload = { notes, appID: application.id };
      send('SAVE_NOTES', payload);
    };

    const updateStatus = status => {
      const notes = document.getElementById('internalNotes').value;
      send('UPDATE_STATUS', {
        status,
        id: copyReqeustId,
        notes,
        appID: application.id,
      });
    };

    return (
      <CopyRequestStyled>
        <div className="__copyReq-header">
          <h1>View copy request application detail</h1>
          <Button onClick={() => navigate('/admin/copy-requests')} outline>
            Back to copy requests
          </Button>
        </div>
        <div className="__copyReq-details">
          <p>
            <strong>Date/Time started:</strong>{' '}
            {dayjs(createdAt).format('MM/DD/YYYY')}
          </p>
          <p>
            <strong>Reference No.:</strong>{' '}
            {application?.refNumber ? application?.refNumber : 'Not Set'}
          </p>
          <p>
            <strong>Payment Status:</strong> <span>Received</span>
          </p>
          <p>
            <strong>Order options:</strong>{' '}
            <span className="">Copy request</span>
          </p>
        </div>
        <div className="__copyReq-content">
          <RoundedBlock title="Applicant Details">
            <div className="__copyReq-block-details">
              <p>
                <strong>Name:</strong>{' '}
                {camelCase(application.applicantInfo.firstName)}{' '}
                {application.applicantInfo.lastName.toUpperCase()}
              </p>
              <p>
                <strong>Email Address:</strong>{' '}
                {application.applicantInfo.email}
              </p>
              <p>
                <strong>Email Address:</strong>{' '}
                {application.applicantInfo.email}
              </p>
            </div>
          </RoundedBlock>
          <RoundedBlock title="Evaluation Details">
            <div className="__copyReq-block-details">
              <p>
                <strong>GCE evaluation reference #:</strong>{' '}
                {application.refNumber}
              </p>
            </div>
          </RoundedBlock>

          <RoundedBlock title="Order Details">
            <div className="__copyReq-block-details">
              <p>
                <strong>Number of copies to be sent:</strong> {copiesRequested}
              </p>
            </div>
            {addresses.length > 0 && (
              <div className="__copyReq-block-details">
                {addresses.map((address, index) => {
                  const { displayName, price } = returnService(
                    services,
                    address.shippingMethod,
                  );
                  return (
                    <AddressStyled key={Math.random()}>
                      <p>
                        <strong>
                          Address {index > 0 ? `${index + 1}:` : ':'}
                        </strong>
                      </p>
                      {constructAddress(address)}
                      <p>
                        <strong>Shipping Method: </strong>
                        {displayName}{' '}
                        <strong>
                          <em>&nbsp; {`($${price}.00)`}</em>
                        </strong>
                      </p>
                    </AddressStyled>
                  );
                })}
                <hr />
              </div>
            )}
            {emails.length > 0 && (
              <div className="__copyReq-block-details">
                <p>
                  <strong>Emails:</strong>
                </p>
                {emails.map((email, index) => {
                  return (
                    <EmailStyled key={Math.random()}>
                      <p id={`email-${index}`}>{email}</p>{' '}
                      <ClipboardButton>
                        <IconButton
                          type="button"
                          onClick={() => clipboardCopy(`email-${index}`)}
                        >
                          <StickyNote />
                        </IconButton>
                        <p
                          id={`email-${index}-copied`}
                          className="icon-button__copied"
                        >
                          Copied to clipboard
                        </p>
                      </ClipboardButton>
                    </EmailStyled>
                  );
                })}
              </div>
            )}
            {returnDocs?.location && (
              <AddressStyled>
                <p>
                  <strong>Return Documents Address:</strong>
                </p>
                {constructAddress(returnDocs)}
                <p>
                  <strong>Shipping Method: </strong>
                  {returnDocsShipping?.displayName}{' '}
                  <strong>
                    <em>{`$${returnDocsShipping?.price}.00`}</em>
                  </strong>
                </p>
              </AddressStyled>
            )}
            {returnDocs?.email && (
              <>
                <p>
                  Return Documents by Email:{' '}
                  <strong>{returnDocs.returnEmail}</strong>
                </p>
              </>
            )}
          </RoundedBlock>

          <InternalNotesStyled className="internal-notes">
            <p>
              <strong>Internal Notes</strong>
            </p>
            <textarea
              id="internalNotes"
              defaultValue={
                application.internalNotes || 'Please enter notes here'
              }
            />
          </InternalNotesStyled>
          <ButtonsCont>
            <Button onClick={saveNotes}>Save</Button>
            {/* <Button onClick={() => updateStatus('Copies Sent')} outline>
              Mail
            </Button> */}
            <Button onClick={() => updateStatus('Complete')} outline>
              Complete
            </Button>
          </ButtonsCont>
          <ConfirmationStyled>
            {(formState.matches('saveNotes') ||
              formState.matches('updateStatus')) && <p>Saving</p>}
            {formState.matches('saved') && <p>Saved!</p>}
          </ConfirmationStyled>
        </div>
      </CopyRequestStyled>
    );
  }

  return <p>Failed to load</p>;
};

export default AdminCopyRequestReview;
