import { gql } from '@apollo/client';
import { captureGraphQLException } from '../../../shared/logger';

export const CREATE_AND_FINALIZE = gql`
  mutation createAndFinalize($applicationId: String!) {
    applications {
      application(id: $applicationId) {
        invoices {
          createAndFinalize {
            id
            finalized
          }
        }
      }
    }
  }
`;

const MARK_APPLICATION_INVOICE_AS_PROCESSING = gql`
  mutation markApplicationInvoiceAsProcessing(
    $applicationId: String!
    $invoiceId: String!
  ) {
    applications {
      application(id: $applicationId) {
        invoices {
          invoice(id: $invoiceId) {
            markAsProcessing
          }
        }
      }
    }
  }
`;

const VOID_INVOICE = gql`
  mutation VOID_INVOICE($applicationId: String!, $invoiceId: String!) {
    applications {
      application(id: $applicationId) {
        invoices {
          invoice(id: $invoiceId) {
            void
          }
        }
      }
    }
  }
`;

export default client => {
  function createAndFinalize(applicationId) {
    const variables = { applicationId };

    return client
      .mutate({
        mutation: CREATE_AND_FINALIZE,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          captureGraphQLException(errors);
          throw new Error('Error creating and finalizing invoice.');
        }

        return data;
      });
  }

  function markApplicationInvoiceAsProcessing({ variables }) {
    return client
      .mutate({
        mutation: MARK_APPLICATION_INVOICE_AS_PROCESSING,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          captureGraphQLException(errors);
          throw new Error('Error while marking invoice as processing.');
        }

        const { markAsProcessing } =
          data.applications.application.invoices.invoice;

        return markAsProcessing;
      });
  }

  function voidInvoice({ applicationId, invoiceId }) {
    const variables = { applicationId, invoiceId };

    return client
      .mutate({
        mutation: VOID_INVOICE,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          captureGraphQLException(errors);
          throw new Error('Error while voiding invoice.');
        }

        return data.applications.me.application.invoices.voidInvoice;
      });
  }

  return {
    createAndFinalize,
    markApplicationInvoiceAsProcessing,
    voidInvoice,
  };
};
