import React from 'react';
import dayjs from 'dayjs';
import Constants from './Constants';

export const returnService = (services, id) => {
  if (id) {
    return services?.find(service => service.id === id) || '';
  }
  return null;
};

/*  
  CONSTRUCTS AN ADDRESS FROM AN OBJECT, IF YOU WANT IT ALL ON ONE LINE ADD TRUE AS PROPERTY

  todo: @theClarkSell note to future self. 
  
  somewhere in this application we're using line1 and address1 interchangeably.
  Not sure where, but it should be addressed. In the meantime we're dealing with it.
*/
export const constructAddress = (address, oneLine = false, services) => {
  const { city, state, postalCode, country, shippingMethod } = address;

  const line1 = address.line1 ? address.line1 : address.address1;
  const line2 = address.line2 ? address.line2 : address.address2;

  let shippingService;
  if (shippingMethod && services)
    shippingService = returnService(services, shippingMethod) || '';

  if (oneLine) {
    return (
      <span>
        {address.company ? `${address.company} ` : ''}
        {address.contact ? `${address.contact} ` : ''}
        {line1} {line2 ? `${line2} ` : ''}
        {city}, {state} {postalCode} {country}
        {shippingMethod && (
          <strong>
            <em>{shippingService && ` ($${shippingService.price}.00)`}</em>
          </strong>
        )}
      </span>
    );
  }

  return (
    <>
      {address.company && <p>{address.company}</p>}
      {address.contact && <p>{address.contact}</p>}
      <p>{line1}</p>
      {line2 && <p>{line2}</p>}
      <p>
        {city}, {state} {postalCode} {country}
      </p>
      {shippingMethod && (
        <p>
          {shippingService && `Shipping method: ${shippingService.displayName}`}{' '}
          <strong>
            <em>{shippingService && `($${shippingService.price}.00)`}</em>
          </strong>{' '}
        </p>
      )}
    </>
  );
};

// FOUND ON: CONFIGURE SETTINGS,
export const constructSelectObject = data => {
  const items = [];
  data.map(item => {
    return items.push({
      id: item.id,
      name: `${item.firstName} ${item.lastName.toUpperCase()}`,
    });
  });
  return items;
};

// SORTING FUNCTIONS USED ON LIST PAGES
export const sortByDate = (a, b, trueCase = false, date) => {
  // the object is loaded and it finds the date key and sets new date
  const da = new Date(a[date]);
  const db = new Date(b[date]);

  // the true case indicates which way the list is sorted bottom -> top and vice versa
  if (trueCase) {
    return db - da;
  }
  return da - db;
};

export const sortByName = (aName, bName, trueCase = false) => {
  // set everything to lower case
  const fa = aName ? aName.toLowerCase() : '';
  const fb = bName ? bName.toLowerCase() : '';

  // the true case indicates which way the list is sorted bottom -> top and vice versa
  if (trueCase) {
    if (fa < fb) {
      return 1;
    }
    if (fa > fb) {
      return -1;
    }
    return 0;
  }
  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
};

export const clipboardCopy = async selector => {
  const copied = document.getElementById(`${selector}-copied`);
  const text = document
    .getElementById(selector)
    .textContent.replace(/,/g, '')
    .trim();
  await navigator.clipboard.writeText(text);
  copied.classList.add('active');
  setTimeout(() => {
    copied.classList.remove('active');
  }, 1500);
};

// FORMAT BYTES
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    ' Bytes',
    ' KB',
    ' MB',
    ' GB',
    ' TB',
    ' PB',
    ' EB',
    ' ZB',
    ' YB',
  ];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / k ** i).toFixed(dm)) + sizes[i];
};

// READ FILE
export function readFile(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => resolve(event.target.result);
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

export const returnServiceByType = (services, type) => {
  return services.filter(service => service.serviceType === type);
};
export const servicesByCountry = (services, country, noPriority = false) => {
  const internationalBool = country !== Constants.LOCATION.UNITED_STATES;
  // if (debug) {
  //    console.log('country is: ', country);
  //    console.log('bool is: ', internationalBool);
  // }
  return services.filter(service => {
    if (internationalBool) {
      if (
        service.displayName.toLowerCase().includes('international') ||
        service.displayName.toLowerCase().includes('int') ||
        service.displayName.toLowerCase().includes('no rush')
      ) {
        // if (debug) console.log('returning int service: ', service);
        return service;
      }
      if (
        !noPriority &&
        service.displayName.toLowerCase().includes('priority')
      ) {
        return service;
      }
      return null;
    }
    if (
      service.displayName.toLowerCase().includes('united states') ||
      service.displayName.toLowerCase().includes('u.s.') ||
      service.displayName.toLowerCase().includes('u.s') ||
      service.displayName.includes('US') ||
      service.displayName.toLowerCase().includes('priority') ||
      service.displayName.toLowerCase().includes('no rush')
    ) {
      // if (debug) console.log('returning local service: ', service);
      return service;
    }
    if (!noPriority && service.displayName.toLowerCase().includes('priority')) {
      return service;
    }
    return null;
  });
};

export const constructSchool = school => {
  return (
    <div className="school">
      {school.school && <p>School: {school.school}</p>}
      {school.country && <p>Country: {school.country}</p>}
      {school.study && <p>Area of Study: {school.study}</p>}
      {school.startDate && school.endDate && (
        <p>
          Attendance: {dayjs(school.startDate).format('DD/MM/YYYY')} -{' '}
          {dayjs(school.endDate).format('DD/MM/YYYY')}{' '}
        </p>
      )}
      {school.degree && <p>Degree/Diploma: {school.degree}</p>}
      {school.degreeDate && (
        <p>Award Date: {dayjs(school.degreeDate).format('MM/YYYY')}</p>
      )}
    </div>
  );
};

export const orderByPrice = arrayToSort => {
  return arrayToSort.sort((a, b) => {
    return a.price - b.price;
  });
};

export function camelCase(str) {
  // Using replace method with regEx
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, word => word.toUpperCase());
}
// THIS IS ADDED DUE TO SOME PEOPLE PUTTING MULTIPLE NAMES IN THE FIRST NAME TEXT BOX. THIS FUNCTION WILL MAKE THE FIRST LETTER OF ALL WORDS IN THE TEXT BOX TO UPPERCASE, BUT WILL NOT REMOVE SPACES LIKE FUNCTION ABOVE.
