import { gql } from '@apollo/client';
import { captureGraphQLException } from '../../shared/logger';

export const FETCH_SERVICES = gql`
  query FETCH_SERVICES {
    services {
      all {
        id
        name
        displayName
        description
        price
        serviceType
      }
    }
  }
`;

const CREATE_CART = gql`
  query CREATE_CART($applicationId: String!, $serviceId: String!) {
    applications {
      me {
        application(id: $applicationId) {
          cart {
            review(serviceId: $serviceId) {
              applicationId
              total
              billTo {
                firstName
                lastName
                company
                phoneNumber
                address {
                  line1
                  city
                  state
                  postalCode
                  country
                }
              }
              lineItems {
                name
                id
                displayName
                description
                price
                serviceType
                quantity
              }
            }
          }
        }
      }
    }
  }
`;

export default client => {
  function fetchServices() {
    return client.query({ query: FETCH_SERVICES }).then(({ data, errors }) => {
      if (errors) {
        captureGraphQLException(errors);
        throw new Error('Error fetching application services.');
      }

      return data.services.all;
    });
  }

  function createCart({ applicationId, serviceId }) {
    const variables = { applicationId, serviceId };

    return client
      .query({
        query: CREATE_CART,
        variables,
      })
      .then(({ data, errors }) => {
        if (errors) {
          captureGraphQLException(errors);
          throw new Error('Error creating application review cart.');
        }

        return data.applications.me.application.cart.review;
      });
  }

  return {
    fetchServices,
    createCart,
  };
};
