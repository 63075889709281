import React from 'react';
import styled from 'styled-components';

const Button = ({ rush, complete, addedRush, review }) => (
  <Key>
    {rush && (
      <div className="item rush">
        <span /> Rush Enabled
      </div>
    )}

    {addedRush && (
      <div className="item rushadded">
        <span /> Added Rush After
      </div>
    )}

    {review && (
      <div className="item review">
        <span /> Review Requested
      </div>
    )}

    {complete && (
      <div className="item">
        <span /> Completed
      </div>
    )}
  </Key>
);

export default Button;
const Key = styled.div`
  display: flex;
  margin-top: 10px;
  .item {
    margin-right: 15px;
    display: flex;
    align-items: center;
    span {
      background-color: #68b6ec;
      width: 1rem;
      height: 1rem;
      display: inline-block;
      margin-right: 5px;
    }

    &.rush {
      span {
        background-color: #ffb195;
      }
    }
    &.rushadded {
      span {
        background-color: #f1a9ff;
      }
    }
    &.review {
      span {
        background-color: #dfb0fc;
      }
    }
  }
`;
