import { gql } from '@apollo/client';
import { captureGraphQLException } from '../../../shared/logger';

const CREATE_INVOICE = gql`
  mutation CREATE_INVOICE($applicationId: String!, $serviceId: String!) {
    applications {
      me {
        application(id: $applicationId) {
          invoices {
            createAndFinalizeAddRushInvoice(serviceId: $serviceId) {
              id
              finalized
            }
          }
        }
      }
    }
  }
`;

const VOID_INVOICE = gql`
  mutation VOID_INVOICE($invoiceId: String!) {
    applications {
      me {
        application(id: $applicationId) {
          invoices {
            voidInvoice(invoiceId: $invoiceId) {
              id
              finalized
            }
          }
        }
      }
    }
  }
`;

export default client => {
  function createInvoice({ applicationId, serviceId }) {
    const variables = { applicationId, serviceId };

    return client
      .mutate({ mutation: CREATE_INVOICE, variables })
      .then(({ data, errors }) => {
        if (errors) {
          captureGraphQLException(errors);
          throw new Error('Error while trying to void invoice.');
        }

        return data.applications.me.application.invoices
          .createAndFinalizeAddRushInvoice;
      });
  }

  function voidInvoice({ applicationID, invoiceId }) {
    const variables = { applicationID, invoiceId };

    return client
      .mutate({ mutation: VOID_INVOICE, variables })
      .then(({ data, errors }) => {
        if (errors) {
          captureGraphQLException(errors);
          throw new Error('Error while trying to void invoice.');
        }

        return data.applications.me.application.invoices.voidInvoice;
      });
  }

  return { createInvoice, voidInvoice };
};
