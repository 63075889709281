import * as yup from 'yup';

export const AddressFormSchema = {
  line1: yup.string().required('Please enter address'),
  line2: yup.string(),
  city: yup.string().required('Please enter a city'),
  state: yup.string().when('country', {
    is: 'United States',
    then: schema =>
      schema
        .required('Please enter a state')
        .test('is-state', 'Please enter a state', value => value !== '--'),
  }),
  postalCode: yup.string().when('country', {
    is: 'United States',
    then: schema => schema.required('Please enter a zip code'),
  }),
  country: yup.string().required('Please choose a country'),
};

export const CompanyFormFieldsSchema = {
  company: yup.string(),
  contact: yup.string().required('Please enter a name'),
  line1: yup.string().required('Please enter address'),
  line2: yup.string(),
  city: yup.string().required('Please enter a city'),
  state: yup.string().when('country', {
    is: 'United States',
    then: schema =>
      schema
        .required('Please enter a state')
        .test('is-state', 'Please enter a state', value => value !== '--'),
  }),
  postalCode: yup.string().when('country', {
    is: 'United States',
    then: schema => schema.required('Please enter a zip code'),
  }),
  country: yup.string().required('Please select a country'),
};

export const CopyRequestFormFieldsSchema = {
  location: yup.string().required('Please choose an option'),
  company: yup.string(),
  contact: yup.string().required('Please enter a name'),
  line1: yup.string().required('Please enter address'),
  line2: yup.string(),
  city: yup.string().required('Please enter a city'),
  state: yup.string().when('location', {
    is: 'US address',
    then: schema =>
      schema
        .required('Please select a state')
        .test('is-state', 'Please select a state', value => value !== '--'),
  }),
  country: yup.string(),
  postalCode: yup.string().when('country', {
    is: 'United States',
    then: schema =>
      schema
        .required('Please enter a state')
        .typeError('Please enter a valid zip/postal code'),
  }),
  shippingMethod: yup
    .string()
    .typeError('Please Select an option')
    .required('Please Select an option'),
};

export const DateSchema = {
  month: yup.string().required('Please choose a month'),
  day: yup.string().required('Please choose a day'),
  year: yup
    .string()
    .matches(/^(19|20)\d{2}$/, 'Please enter year ex. 1994')
    .required('Please enter a year'),
};
// Use this schema if  you are validating and need to compare two dates (IE School form on step 2).
export const DateSchemaNotRequired = {
  month: yup.string(),
  day: yup.string(),
  year: yup.string().matches(/^(19|20)\d{2}$/, 'Please enter year ex. 1994'),
};

export const EducationFormSchema = {
  country: yup.string().required('Please enter the country of the school'),
  name: yup
    .string()
    .required('Please enter name of the school or institution.'),
  areaOfStudy: yup.string().required('Please enter an area of study'),
  startDate: yup.object().shape(DateSchemaNotRequired),
  startDateTime: yup
    .number()
    .typeError()
    .transform(value =>
      Number.isNaN(value) || value === null || value === undefined ? 0 : value,
    )
    .required('Please enter a valid date'),
  degree: yup.object().shape({
    notApplicable: yup.boolean(),
    // title: yup.string().required('Please enter a Degree/Diploma title'),
    title: yup.string().when('notApplicable', {
      is: false,
      then: schema => schema.required('Please enter a Degree/Diploma title'),
    }),
  }),
  endDate: yup.object().shape(DateSchemaNotRequired),
  endDateTime: yup
    .number()
    .typeError()
    .min(yup.ref('startDateTime'), 'End date must be after start date'),
  awardDate: yup.object().when('degree.notApplicable', {
    is: false,
    then: schema => schema.shape(DateSchemaNotRequired),
  }),
  awardDateTime: yup
    .number()
    .typeError()
    .nullable()
    .when('degree.notApplicable', {
      is: false,
      then: schema =>
        schema.min(yup.ref('endDateTime'), 'Award date must be after end date'),
    }),
};

export const NameSchema = {
  first: yup.string().required('Please enter your first name'),
  middle: yup.string(),
  last: yup.string().required('Please enter your last name'),
};
export const PrevEvaluationSchema = {
  option: yup.string().required('Please choose an option'),
  number: yup
    .string()
    .nullable()
    .when('option', {
      is: 'Yes',
      then: schema => schema.required('Please enter a reference number'),
    }),
  date: yup.string().when('option', {
    is: 'Yes',
    then: schema =>
      schema
        .matches(
          /(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d/,
          'Please make sure date is in format of dd/mm/yyyy',
        )
        .required('Please enter date of Application'),
  }),
};
