import { gql } from '@apollo/client';
import { captureGraphQLException } from '../../shared/logger';

const CREATE_INVOICE = gql`
  mutation CREATE_INVOICE($applicationId: String!, $serviceId: String!) {
    applications {
      me {
        application(id: $applicationId) {
          invoices {
            createAndFinalizeApplicationReview(serviceId: $serviceId) {
              id
              finalized
            }
          }
        }
      }
    }
  }
`;

export default client => {
  function createInvoice({ applicationId, serviceId }) {
    const variables = { applicationId, serviceId };

    return client
      .mutate({ mutation: CREATE_INVOICE, variables })
      .then(({ data, errors }) => {
        if (errors) {
          captureGraphQLException(errors);
          throw new Error('Create Invoice Failed for Application Review');
        }

        return data.applications.me.application.invoices
          .createAndFinalizeApplicationReview;
      });
  }

  return { createInvoice };
};
